@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Inter&family=Radio+Canada:wght@400;500;600;700&display=swap');
 
  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Inter&family=Radio+Canada:wght@400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Inter&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');

body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
}
*{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
.ql{
    list-style-type: none;
}
.container{
	max-width: 1170px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
}
.footer{
	background-color: #1B1B1B;
     padding: 1px 0; 
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #FF5E15;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
    list-style-type: none;
}
.footer-col ul li a{
	font-size: 16px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}
.footer_heading{
    color: #FFFFFF;
    font-family: Radio Canada;
font-size: 24px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.sub_contact{
  color: #FFFFFF;
  font-family: Radio Canada;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
  
}
.sub_contact_detail{
    color: #ADADAD;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

}
.contact_img{
    width: 45px !important;
height: 45px !important;


}
.input_subscription{
    border: 1px solid #2323231F;
    width: 280px;
height: 50px;
top: 178px;
left: 40px;
padding: 16px 3px 16px 26px;
gap: 0px;
border-radius: 5px;
border: 1px 0px 0px 0px;

}
.input_submit{
    width: 151.11px;
height: 56px;
top: 430px;
left: 483px;
padding: 0px 21.11px 0px 20px;
gap: 0px;
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 14px;
text-align: center;
color: #FFFFFF;
background-color: #FF5E15;
border: 1px solid #FF5E15;
}
.ql{
    padding: 0;
    
}
.ql_links{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #ADADAD;
}
.upper_footer{
    width: 100%;
height: 170px;

border-radius: 3px;
background-color: #00235A;
transform: translateY(-60%);
margin-top: 20px;
padding-top: 30px;
}
.main_footer{
margin-top: 200px;
}
.upper_main{
    color: #FFFFFF;
    font-family: Poppins;
font-size: 35px;
font-weight: 600;
line-height: 46px;
letter-spacing: 0em;
text-align: left;
padding-left: 70px;
}
.uperfotter_phone{
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;

}
.uperfotter_phone1{
    color: #FFFFFF;
    font-family: Fira Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    
    
}

@media screen and (max-width:375px) {
    .upper_main{
        color: #FFFFFF;
        font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
    }
    .uperfotter_phone{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }
    .uperfotter_phone1{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }
    .phone_circle{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width:425px) {
    .upper_main{
        color: #FFFFFF;
        font-family: Poppins;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 16px !important;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
    }
    .uperfotter_phone{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 10px !important;
        font-weight: 500;
        line-height: 16px !important;
        letter-spacing: 0em;
        text-align: left;
    }
    .uperfotter_phone1{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 10px !important;
        font-weight: 500;
        line-height: 16px !important;
        letter-spacing: 0em;
        text-align: left;
    }
    .phone_circle {
        width: 75% !important;
        height: 75% !important;
    }
}
@media screen and (max-width:768px) {
    .upper_main{
        color: #FFFFFF;
        font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
    }
    .uperfotter_phone{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }
    .uperfotter_phone1{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }
    .phone_circle{
        width: 70%;
        height: 70%;
    }
}
@media screen and (max-width:991px) {
    .upper_main{
        color: #FFFFFF;
        font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 10px;
    }
    .uperfotter_phone{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }
    .uperfotter_phone1{
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
    }

}

.footer_last{
    background-color: #00235A;
    color: #FFFFFF;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: center;

}

/* @media screen and (max-width: 458px){
    .phone_circle {
        height: 50% !important;
        width: 104% !important;
    }
} */

.heading_related_post1 {
    color: #ADADAD;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

@media screen and (max-width:425px) {
    .upper_footer {
        width: 100%;
        height: 115px;
        border-radius: 3px;
        background-color: #00235A;
        transform: translateY(-60%);
        margin-top: 20px;
        padding-top: 30px;
    }
}