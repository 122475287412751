
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap');
  
  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter:wght@400;600&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');

  .nav_itemmenu{
gap: 90px;
}
.nav_subitemmenu{
    gap: 60px;  
}
.contact_navbar{
    width: 151.11px;
height: 56px;
top: 29px;
left: 1418.89px;
padding: 0px, 30.11px, 0px, 29px;
background: #00235A !important;
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 14px;
letter-spacing: 0em;
text-align: center;
color: #FFFFFF;
}
  .navbar {
    background-color: #ffffff;
}

.navbar-brand {
    color: #ffffff;
    font-weight: bold;
}

.navbar-nav .nav-item .nav-link {
    font-family: Radio Canada;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
     color: #1B1B1B;
    position: relative;
    transition: 0.3s;
    transform: translateX(0);
    margin-right: 10px;
}
.navbar-dark .navbar-toggler-icon {
    background-color: #1B1B1B;
}
.navbar-nav .nav-item .nav-link:hover {
    transform: translateX(10px);
    /* Hover effect with a transform */
    color: #9C27B0;
}

.navbar-nav .nav-item .nav-link::before {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transition: 0.3s;
}

.navbar-nav .nav-item .nav-link:hover::before {
    width: 100%;
 
}

/* Center the logo on mobile devices */
@media (max-width: 767px) {
    .navbar-brand {
        text-align: center;
        width: 54%;
    }

    .navbar-toggler {
        margin-left: auto;
        /* Push the toggler icon to the right */
    }

    .navbar-collapse {
        text-align: center;
    }
}
@media screen and (max-width:1280px){
    .nav_itemmenu{
        gap: 0px;
        }
        .nav_subitemmenu{
            gap: 0px;  
        }
}
    .btn-login {
        background-color: #9C27B0;
        color: #fff;
        /* border: none; */
        border-radius: 0px;
        padding: 8px 20px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        border: 1px solid #9C27B0;
    }
    .btn-login:hover{
        background-color: white;
        color: #9C27B0;
        border: 1px solid #9C27B0;
    }
.gen_sol{
    background-image: url(../../Assets/navbar_back.png);
    width: 100%;
height: 569px;
background-repeat: no-repeat;


}

.navbar_main_content{
    font-family: Radio Canada;
    font-size: 26px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color:#FFFFFF ;
    margin: 0;
    padding: 0;
}
.navbar_main_subhead{
    font-family: Radio Canada;
    font-size: 55px;
    font-weight: 700;
    line-height: 110px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
}
.navbar_main_subheadcolor{
    font-family: Radio Canada;
    font-size: 55px;
    font-weight: 700;
    line-height: 110px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF5E15;
    margin: 0;
    padding: 0;
}
.navbar_main_head{
    font-family: Radio Canada;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
}
.navbarcontent_row{
    padding-top: 160px !important;
}

.callnow_btn{
    width: 151.11px;
    height: 56px;
    top: 410px;
    left: 414.11px;
    padding: 0px, 27.22px, 0px, 26.89px;
    border: 1px solid #FFFFFF;
    font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 14px;
letter-spacing: 0em;
text-align: center;
color: #ffffff;
background: transparent !important;
}
.call_btn{
    width: 151.11px;
height: 56px;
top: 410px;
left: 248px;
padding: 0px, 17.11px, 0px, 17px;
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 14px;
letter-spacing: 0em;
text-align: center;
color:#FFFFFF ;
}
.call_btn:hover{
   
color:#FF5E15 ;
}
.discover_btn{
width: 151.11px;
height: 56px;
top: 410px;
left: 248px;
padding: 0px, 17.11px, 0px, 17px;
background: #FF5E15;
border: 1px solid #FF5E15;
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 14px;
letter-spacing: 0em;
text-align: center;
color:#FFFFFF ;
}
@media screen and (max-width:1280px){
    .navbar_main_subhead {
        font-family: Radio Canada;
        font-size: 60px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 60px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
}
@media screen and (max-width:846px) {
    .navbar_main_content{
        font-family: Radio Canada;
        font-size: 22px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color:#FFFFFF ;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subhead{
        font-family: Radio Canada;
        font-size: 49px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 49px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
    .navbar_main_head{
        font-family: Radio Canada;
        font-size: 38px;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    } 
    .visible-br {
        display: none; /* Hide line breaks */
      }
}
@media screen and (max-width:768px) {
    .navbar_main_content{
        font-family: Radio Canada;
        font-size: 22px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color:#FFFFFF ;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subhead{
        font-family: Radio Canada;
        font-size: 40px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 40px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
    .navbar_main_head{
        font-family: Radio Canada;
        font-size: 38px;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    } 
    .visible-br {
        display: none; /* Hide line breaks */
      }
}


@media screen and (max-width:530px) {
    .navbar_main_content{
        font-family: Radio Canada;
        font-size: 18px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color:#FFFFFF ;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subhead{
        font-family: Radio Canada;
        font-size: 40px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 40px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
    .navbar_main_head{
        font-family: Radio Canada;
        font-size: 30px;
        font-weight: 400;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    } 
    .visible-br {
        display: none; /* Hide line breaks */
      }
}


@media screen and (max-width:435px) {
    .navbar_main_content{
        font-family: Radio Canada;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color:#FFFFFF ;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subhead{
        font-family: Radio Canada;
        font-size: 30px;
        font-weight: 700;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 30px;
        font-weight: 700;
        line-height: 80px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
    .navbar_main_head{
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    } 
    .visible-br {
        display: none; /* Hide line breaks */
      }
}

@media screen and (max-width:375px) {
    .navbar_main_content{
        font-family: Radio Canada;
        font-size: 14px;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color:#FFFFFF ;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subhead{
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
    .navbar_main_head {
        font-family: Radio Canada;
        font-size: 24px;
        font-weight: 400;
        line-height: 6px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .visible-br {
        display: none; /* Hide line breaks */
      }
}

@media screen and (min-width: 436px) and (max-width: 536px) {
    .navbar_main_subhead{
        font-family: Radio Canada;
        font-size: 30px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        margin: 0;
        padding: 0;
    }
    .navbar_main_subheadcolor{
        font-family: Radio Canada;
        font-size: 30px;
        font-weight: 700;
        line-height: 110px;
        letter-spacing: 0em;
        text-align: left;
        color: #FF5E15;
        margin: 0;
        padding: 0;
    }
}
