.services-containerabout {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: 100%;
    justify-content: space-around; 
  }
  
  .service_about {
    flex: 0 1 calc(25% - 20px); 
    padding: 10px;
    text-align: center;
    box-shadow: 0px 4px 10px 0px #00000026;
    border-radius: 10px 0px 0px 0px;
    
    width: 280px;
    height: 386px;
    gap: 0px;
    border-radius: 10px 0px 0px 0px;
  
    

  }
  
  .service_about:hover {
    opacity: 1;
  }
  
  .service_about img {
    max-width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .service-content {
    padding: 10px;
   
  }
  
  .service-button_about {
    background-color: #00235A;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 0px;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
  }
  .contact_ushref1:hover{
    color: red;
  }
  .service_content{
color: #7E7E7E;
font-family: Radio Canada;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: center;
display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;
overflow: hidden;
 text-overflow: ellipsis; 
  }
  .service_heading{
    color: #373737;
    font-family: Radio Canada;
font-size: 17px;
font-weight: 700;
line-height: 21.6px;
text-align: center;

  }
  .dedicated_services{
color: #00235A;
font-family: Radio Canada;
font-size: 44px;
font-weight: 700;
line-height: 54px;
text-align: center;

  }
  .dedicated_services_content{
color: #00235A;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 28px;
text-align: center;

  }
  @media (max-width: 1000px) {
    .service_about {
      flex: 0 1 calc(33.333% - 20px);
      width: 220px;
      height: 100%;
    }
  }
  
  @media (max-width: 700px) {
    .service_about {
      flex: 0 1 calc(50% - 20px);
      width: calc(50% - 20px);
      height: auto;
    }
  }
  
  @media (max-width: 500px) {
    .service_about {
      flex: 0 1 100%;
      width: 100%;
      height: auto;
    }
  }